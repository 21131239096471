var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"filter-select-item",style:({
    'padding-right': _vm.isDirectionColumnImg ? '0' : '',
    'padding-bottom': _vm.isDirectionColumnImg ? '0' : '',
  }),attrs:{"adaflag":_vm.item.label,"aria-label":_vm.item.label,"aria-hidden":"true","role":"button"},on:{"click":function($event){return _vm.clickItem($event)}}},[_c('span',{staticClass:"haptics-feedback",class:{
      'filter-select-item__inner_active': _vm.active && !_vm.isDirectionColumnImg,
      'filter-select-item__inner': !_vm.isDirectionColumnImg,
      'filter-select-item__img_active': _vm.active && _vm.isDirectionColumnImg,
      'filter-select-item__img': _vm.isDirectionColumnImg,
    }},[(_vm.isDirectionColumnImg)?[_c('div',{staticClass:"filter-select-item__img_wrapper"},[(_vm.item.label_img)?_c('img',{attrs:{"src":_vm.transformImg({ img: _vm.item.label_img }),"alt":_vm.item.label}}):_vm._e()]),_vm._v(" "),_c('span',{staticClass:"filter-select-item__img_text"},[_vm._v(_vm._s(_vm.item.label))])]:[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.active && !_vm.isRW),expression:"active && !isRW"}],staticClass:"filter-select-item__inner-cancel"}),_vm._v(" "),(_vm.item.label_img)?_c('img',{attrs:{"src":_vm.transformImg({ img: _vm.item.label_img }),"alt":_vm.item.label}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"filter-select-item__inner_text"},[_vm._v(_vm._s(_vm.item.label))])],_vm._v(" "),(_vm.item.hotMark)?_c('span',{staticClass:"filter-select-item__hot-badge"},[_vm._v("\n      Hot\n    ")]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }